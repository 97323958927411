<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {computed, onMounted, ref, watch} from "vue";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";

const datalist = ref(null)
const route = useRoute()
const router = useRouter()

const Keywords = ref()
const current = ref(1)

const lastPage = computed(() => {
  return Math.ceil(datalist.value.count / 12)
})

const prevPage = computed(() => {
  if (current.value > 1) {
    return current.value - 1;
  } else {
    return 1
  }
})

const nextPage = computed(() => {
  if (current.value < lastPage.value) {
    return current.value + 1;
  } else {
    return current.value
  }
})

const pages = computed(() => {
      const pages = []
      for (let i = 1; i <= lastPage.value; i++) {
        if (i === current.value || i === lastPage.value || (i >= current.value - 1 && i <= lastPage.value + 1)) {
          pages.push(i)
        } else if (pages[pages.length - 1] !== '...') {
          pages.push('...')
        }
      }
      return pages
    }
)

function get_product() {
  let url = '/api/products/'
  const page = Number(route.query.page)
  const title = route.query.title
  const category = route.query.category_id_category
  const param = new URLSearchParams()

  if (page) {
    param.append('page', page)
  }
  if (title) {
    param.append('title', title)
  }
  if (category) {
    param.append('category_id_category', category)
  }
  url = url + '?' + param.toString();
  axios
      .get(url)
      .then(response => {
        datalist.value = response.data
      })
      .catch(error => {
        console.log(error)
      });
}

function getPageFromUrl() {
  const page = Number(route.query.page);
  return page ? page : 1
}

function getToPage(page) {
  current.value = page
  const query = {...route.query}
  query.page = page
  router.push({query})
}

function getSearch() {
  const search = Keywords.value.trim()
  router.push({
    query: {title: search}
  })
}

onMounted(() => {
  const scripts = [
    '/static/js/jquery-3.6.0.min.js',
    '/static/js/bootstrap.min.js',
    '/static/js/popper.min.js',
    '/static/js/magnific-popup.min.js',
    '/static/js/slick.min.js',
    '/static/js/counterup.min.js',
    '/static/js/waypoints.min.js',
    '/static/js/jquery-ui-min.js',
    '/static/js/jquery.nice-select.min.js',
    '/static/js/theia-sticky-sidebar.min.js',
    '/static/js/main1.js'
  ];

  function loadScriptsSequentially(scripts, index = 0) {
    if (index < scripts.length) {
      const script = document.createElement('script');
      script.src = scripts[index];
      script.onload = () => loadScriptsSequentially(scripts, index + 1);
      document.body.appendChild(script);
    }
  }

  loadScriptsSequentially(scripts);

  current.value = getPageFromUrl()
  get_product()
})

watch(() => route.query,
    () => {
      get_product()
    }, {immediate: true}
)

</script>

<template>
  <Header/>
  <div class="lt-breadcrumb">
    <div class="breadcrumb-content">
      <div class="container">
        <div class="d-flex justify-content-between">
          <div class="title">
            <h1>Products</h1>
          </div>
          <ol class="breadcrumb align-self-center">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Products</li>
          </ol>
        </div>
      </div><!-- /.container -->
    </div><!-- /.breadcrumb-content -->
  </div><!-- /.lt-breadcrumb -->

  <div class="lt-page-content bg-color">
    <div class="lt-section">
      <div class="section-content lt-blog section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-7 col-lg-8 lt-sticky">
              <div class="theiaStickySidebar">
                <div class="row">
                  <div v-if="datalist && datalist.results" v-for="list in datalist.results" :key="list.id"
                       class="col-lg-6">
                    <div class="tl-post">
                      <div class="entry-header">
                        <div class="entry-thumbnail">
                          <a href="blog-details.html"><img :src="list.images[0].image" alt="Image"
                                                           style="width: 350px; height: 350px"
                                                           class="img-fluid"></a>
                        </div>
                      </div>
                      <div class="entry-content">
                        <h2 class="entry-title"><a :href="'/products/' + list.title">{{ list.title }}</a></h2>
                        <a class="read-more" :href="'/products/' + list.title">View More <span
                            class="fa fa-arrow-right"></span></a>
                      </div>
                    </div><!-- /.tl-post  -->
                  </div>
                </div><!-- /.row -->

                <div class="lt-pagination">
                  <ul class="pagination ">
                    <li><a @click="getToPage(prevPage)" class="page-numbers" href="#"><i class="fa fa-chevron-left"></i></a>
                    </li>
                    <li v-if="datalist && datalist.results" v-for="page in pages" :key="page">
                      <a v-if="page === current.value" @click="getToPage(page)" class="page-numbers current" href="#">
                        {{ page }}
                      </a>
                      <a v-else-if="page === '...'" @click="getToPage(page)" class="page-numbers current" href="#">
                        {{ page }}
                      </a>
                      <a v-else @click="getToPage(page)" class="page-numbers current" href="#">{{ page }}</a>
                    </li>
                    <li><a @click="getToPage(nextPage)" class="page-numbers" href="#"><i
                        class="fa fa-chevron-right"></i></a></li>
                  </ul>
                </div><!-- /.lt-pagination -->
              </div>
            </div>
            <div class="col-md-5 col-lg-4 lt-sticky">
              <div class="lt-sidebar theiaStickySidebar">
                <div class="widget-area">
                  <div class="widget widget_search">
                    <h3 class="widget_title">Search</h3>
                    <form action="#" class="search-form">
                      <input v-model="Keywords" type="search" class="search-field" placeholder="Search here...">
                      <input v-on:click.prevent="getSearch" type="submit" class="search-submit" value="">
                    </form>
                  </div><!-- /.widget -->

                  <div class="widget widget_categories">
                    <h3 class="widget_title">Categories</h3>
                    <ul>
                      <li><a href="/products/?category_id_category=Aluminum Window">Aluminum Windows</a></li>
                      <li><a href="/products/?category_id_category=Aluminum Door">Aluminum Door</a></li>
                      <li><a href="/products/?category_id_category=Sunroom">Sunroom</a></li>
                    </ul>
                  </div><!-- /.widget -->
                </div><!-- /.widget-area -->
              </div>
            </div>
          </div><!-- /.row -->
        </div><!-- /.container -->
      </div><!-- /.section-content -->
    </div><!-- /.lt-section -->
  </div><!-- /.lt-page-content -->
  <Footer/>
</template>

<style scoped lang="scss">

</style>