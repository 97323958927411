<script setup>

import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import {onMounted} from "vue";

onMounted(() => {
  const scripts = [
    '/static/js/jquery-3.6.0.min.js',
    '/static/js/bootstrap.min.js',
    '/static/js/popper.min.js',
    '/static/js/magnific-popup.min.js',
    '/static/js/slick.min.js',
    '/static/js/counterup.min.js',
    '/static/js/waypoints.min.js',
    '/static/js/jquery-ui-min.js',
    '/static/js/jquery.nice-select.min.js',
    '/static/js/theia-sticky-sidebar.min.js',
    '/static/js/main1.js'
  ];

  function loadScriptsSequentially(scripts, index = 0) {
    if (index < scripts.length) {
      const script = document.createElement('script');
      script.src = scripts[index];
      script.onload = () => loadScriptsSequentially(scripts, index + 1);
      document.body.appendChild(script);
    }
  }

  loadScriptsSequentially(scripts);
})
</script>

<template>
  <Header/>
  <div class="lt-section" id="about">
    <div class="section-content about-content section-padding">
      <div class="about-thumb">
        <img src="/static/picture/about.png" alt="Image" class="img-fluid">
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-thumb-content">
              <div class="thumb">
                <img src="/static/picture/about2.jpg" alt="Image" class="img-fluid">
              </div>
              <div class="thumb thumb-2">
                <img src="/static/picture/about1.jpg" alt="Image" class="img-fluid">
              </div>
              <div class="lt-video">
                <a href="https://www.youtube.com/watch?v=rA81nm0NKYM"><span class="icon"><i class="fa fa-play"
                                                                                            aria-hidden="true"></i></span>Watch
                  Video</a>
              </div>
            </div><!-- /.about-thumb-content -->
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="lt-about">
              <div class="section-title">
                <span>Established in 2008</span>
                <h1><span>Dongxia  Aluminum</span> Window & Door Co., Ltd</h1>
                <p>The company relies on modern standard factory buildings and management systems, introduces advanced
                  German door and window technology, gathers senior designers in the industry, focuses on aluminum alloy
                  doors and windows for 10 years, and provides integrated services from design to sales. The product
                  line covers balcony sliding doors, indoor partition doors, sliding windows, casement windows, and
                  folding doors, all of which follow high standard production processes to ensure excellent quality.</p>
              </div>
              <a href="/about" class="btn btn-primary radius">Read More</a>
            </div>
          </div>
        </div><!-- /.row -->
      </div><!-- /.container -->
    </div><!-- /.section-content -->
  </div><!-- /.lt-section -->

  <div class="lt-section">
    <div class="section-content section-padding text-center bg-image section-before"
         style="background-image: url(/static/image/featured-bg-1.jpg);">
      <div class="container">
        <div class="feature-content">
          <div class="row">
            <div class="col-md-4">
              <div class="feature style-one">
                <div class="feature-icon">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <h2>Profession</h2>
                <p>Deep industry development, leading technology, excellent design and quality assurance.</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature style-one">
                <div class="feature-icon">
                  <i class="fa fa-bolt" aria-hidden="true"></i>
                </div>
                <h2>Experience</h2>
                <p>Ten years of accumulation, market verification, mature and reliable, comprehensive service.</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature style-one">
                <div class="feature-icon">
                  <i class="fa fa-link" aria-hidden="true"></i>
                </div>
                <h2>Effectiveness</h2>
                <p>Standard process, quick response, precise production, and efficient service.</p>
              </div>
            </div>
          </div>
        </div><!-- feature-content -->
      </div><!-- /.container -->
    </div><!-- /.section-content -->
  </div><!-- /.lt-section -->

  <div class="lt-section">
    <div class="section-content lt-get-a-quote section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 align-self-center">
            <div class="quote-info">
              <div class="thumb">
                <img src="/static/picture/quote-thumb.jpg" alt="Quote Image" class="img-fluid">
              </div>
              <div class="quote-text">
                <h2>Use the form below to get in touch with us.</h2>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="quote-form">
              <h1>Get a Free Online Quote Now!</h1>
              <span class="border"></span>
              <form action="#">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Name*">
                  <input type="email" class="form-control" placeholder="Email*">
                  <input type="text" class="form-control" placeholder="Subject*">
                  <textarea name="message" class="form-control" placeholder="Message*" cols="30" rows="10"></textarea>
                  <input type="submit" value="Submit" class="btn btn-primary">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div><!-- /.container -->
    </div><!-- /.section-content -->
  </div><!-- /.lt-section -->

  <div class="lt-section">
    <div class="section-content section-padding lt-testimonial bg-image"
         style="background-image: url(/static/image/testimonial-1.jpg);">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="faq-accordion" id="faq-accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                          aria-controls="collapseOne">What are the R&D and design advantages of Dongxia?
                  </button>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#faq-accordion">
                  <div class="card-body">
                    <p>Introducing German technology, a team of senior designers, launching new products every year,
                      cutting-edge design, and excellent quality.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                          aria-expanded="false" aria-controls="collapseTwo">How is the production quality guaranteed?
                  </button>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#faq-accordion">
                  <div class="card-body">
                    <p>Strictly implement standard processes, multi-link quality inspection, modern factories and
                      equipment to ensure accuracy and stability.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
                          aria-expanded="false" aria-controls="collapseThree">Is the product variety rich?
                  </button>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#faq-accordion">
                  <div class="card-body">
                    <p>Rich in variety, covering balconies, indoor sliding doors, windows and folding doors, etc., to
                      meet diverse needs.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingfour">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefour"
                          aria-expanded="false" aria-controls="collapsefour">What is the service concept?
                  </button>
                </div>
                <div id="collapsefour" class="collapse" aria-labelledby="headingfour" data-parent="#faq-accordion">
                  <div class="card-body">
                    <p>Reputation first, customer first, professional focus, and full-service.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFive">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive"
                          aria-expanded="false" aria-controls="collapseFive">How is the sales network?
                  </button>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#faq-accordion">
                  <div class="card-body">
                    <p>600+ specialty stores at home and abroad, covering a wide range, exported to many countries, and
                      responding quickly.</p>
                  </div>
                </div>
              </div>
            </div><!-- /.faq-accordion -->
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="testimonial-content testimonial-style-1 text-center">
              <div class="testimonial-nav-slider">
                <div class="testimonial-author">
                  <div class="author-image">
                    <img src="/static/picture/author-1.png" alt="Image" class="img-fluid">
                  </div>
                </div><!-- /testimonial-author -->

                <div class="testimonial-author">
                  <div class="author-image">
                    <img src="/static/picture/author-2.png" alt="Image" class="img-fluid">
                  </div>
                </div><!-- /testimonial-author -->

                <div class="testimonial-author">
                  <div class="author-image">
                    <img src="/static/picture/author-2.png" alt="Image" class="img-fluid">
                  </div>
                </div><!-- /testimonial-author -->

                <div class="testimonial-author">
                  <div class="author-image">
                    <img src="/static/picture/author-3.png" alt="Image" class="img-fluid">
                  </div>
                </div><!-- /testimonial-author -->
              </div><!-- testimonial-nav-slider -->

              <div class="testimonial-slider">
                <div class="testimonial">
                  <h2>David Smith</h2>
                  <span class="client-role">Software Engineer</span>
                  <p>Efficiently formulate viral data for error-free users. Distinctively provide access to fully tested
                    total linkage rather than process-centric infrastructures.</p>
                  <div class="quote-icon"><img src="/static/picture/quote1.png" alt="Image" class="img-fluid"></div>
                </div>

                <div class="testimonial">
                  <h2>David Smith</h2>
                  <span class="client-role">Software Engineer</span>
                  <p>Efficiently formulate viral data for error-free users. Distinctively provide access to fully tested
                    total linkage rather than process-centric infrastructures.</p>
                  <div class="quote-icon"><img src="/static/picture/quote1.png" alt="Image" class="img-fluid"></div>
                </div>

                <div class="testimonial">
                  <h2>David Smith</h2>
                  <span class="client-role">Software Engineer</span>
                  <p>Efficiently formulate viral data for error-free users. Distinctively provide access to fully tested
                    total linkage rather than process-centric infrastructures.</p>
                  <div class="quote-icon"><img src="/static/picture/quote1.png" alt="Image" class="img-fluid"></div>
                </div>
              </div><!-- testimonial-slider -->
            </div><!-- testimonial-content -->
          </div>
        </div><!-- /.row -->
      </div><!-- /.container -->
    </div><!-- /.section-content -->
  </div><!-- /.lt-section -->

  <Footer/>
</template>

<style scoped lang="scss">

</style>