<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import axios from "axios";

const datalist = ref({})
const route = useRoute()

function getToUrl(param) {
  let url = '/api/products/'
  url = url + '?title=' + param
  axios
      .get(url)
      .then(response => {
        datalist.value = response.data.results[0]

      })
      .catch(error => {
        console.log(error)
      })
}

onMounted(() => {
  const param = route.params.str;
  getToUrl(param);
  const scripts = [
    '/static/js/jquery-3.6.0.min.js',
    '/static/js/bootstrap.min.js',
    '/static/js/popper.min.js',
    '/static/js/magnific-popup.min.js',
    '/static/js/slick.min.js',
    '/static/js/counterup.min.js',
    '/static/js/waypoints.min.js',
    '/static/js/jquery-ui-min.js',
    '/static/js/jquery.nice-select.min.js',
    '/static/js/theia-sticky-sidebar.min.js',
    '/static/js/main1.js'
  ];

  const loadDelay = 500; // 每个脚本加载之间的延迟时间（毫秒）

  function loadScriptsSequentially(scripts, index = 0) {
    if (index < scripts.length) {
      const script = document.createElement('script');
      script.src = scripts[index];
      script.onload = () => {
        setTimeout(() => {
          loadScriptsSequentially(scripts, index + 1);
        }, loadDelay); // 延迟加载下一个脚本
      };
      document.body.appendChild(script);
    }
  }

  loadScriptsSequentially(scripts);
});


</script>

<template>
  <Header/>
  <div class="property-content">
    <div class="lt-page-content bg-color">
      <div class="lt-section">
        <div class="section-content property-details section-padding">
          <div class="container">
            <div class="property">
              <div class="property-info">
                <div class="d-flex">
                  <div class="property-status">
                    <a href="#">Hot</a>
                    <a href="#">Sale</a>
                  </div>
                  <ul class="global-list d-flex align-self-center lt-rating">
                    <li><i class="fa fa-star-o" aria-hidden="true"></i></li>
                    <li><i class="fa fa-star-o" aria-hidden="true"></i></li>
                    <li><i class="fa fa-star-o" aria-hidden="true"></i></li>
                    <li><i class="fa fa-star-o" aria-hidden="true"></i></li>
                    <li><i class="fa fa-star-o" aria-hidden="true"></i></li>
                  </ul>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="left-content">
                    <h2 v-if="datalist">{{ datalist.title }}</h2>
                    <div class="d-flex map-location">
                      <div class="left-content">
                        <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                        <span v-if="datalist">{{ datalist.overview }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-top">
                  <div class="d-flex justify-content-between">
                    <div class="meta">

                    </div>
                    <div class="print-heart align-self-center">

                    </div>
                  </div>
                </div>
              </div><!-- /.property-info -->

              <div class="property-details-slider">
                <div v-if="datalist && datalist.images" v-for="image in datalist.images" :key="image"
                     class="thumb" style="background-position: center">
                  <img v-if="image" :src="image.image" alt="Image" class="img-fluid"
                       style="width: 500px; height: 500px">
                </div>
              </div>
              <div class="details-slider-nav">
                <div v-if="datalist && datalist.images" v-for="image in datalist.images" :key="image"
                     class="thumb" style="background-position: center">
                  <img v-if="image" :src="image.image" alt="Image" class="img-fluid"
                       style="width: 100px; height: 100px">
                </div>
              </div>
              <div class="row">
                <div class="col-md-7 col-lg-8 lt-sticky">
                  <div class="theiaStickySidebar">
                    <div class="bg-white sub-section property-description">
                      <div>
                        <h2>Property Description</h2>
                      </div>
                      <p v-if="datalist" v-html="datalist.description"></p>
                    </div><!-- /.property-description -->

                    <div class="bg-white sub-section amenities">
                      <div class="title">
                        <h2>Detail and Features</h2>
                      </div>
                      <p v-if="datalist" v-html="datalist.feature"></p>

                    </div><!-- /.amenities -->


                    <div class="bg-white sub-section">
                      <div>
                        <h2>Video</h2>
                      </div>
                      <div class="lt-video">
                        <div class="thumb">
                          <img v-if="datalist && datalist.images" :src="datalist.images[0].image" alt="Image"
                               class="img-fluid" style="width: 350px; height: 350px;">
                        </div>

                        <a href="#"><span class="icon"><i class="fa fa-play" aria-hidden="true"></i></span></a>
                      </div>
                    </div><!-- /.attachments -->
                  </div>
                </div>
                <div class="col-md-5 col-lg-4">
                  <div>
                    <div class="">
                      <div class="widget contact-widget">
                        <h3 class="widget_title">Schedule a Tour</h3>
                        <form action="#" class="lt-form">
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Name">
                            <div class="input-addon">
                              <i class="fa fa-user-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="Phone">
                            <div class="input-addon">
                              <i class="fa fa-phone" aria-hidden="true"></i>
                            </div>
                          </div>
                          <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email">
                            <div class="input-addon">
                              <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            </div>
                          </div>

                          <div class="form-group">
                            <textarea cols="30" rows="10" class="form-control" placeholder="Your Message"></textarea>
                            <div class="input-addon">
                              <i class="fa fa-comment-o" aria-hidden="true"></i>
                            </div>
                          </div>
                          <input type="submit" value="Send Message" class="btn btn-primary">
                        </form>
                      </div><!-- /.widget -->
                    </div><!-- /.widget-area -->
                  </div>
                </div>
              </div><!-- /.row -->
            </div><!-- /.property -->
          </div><!-- /.container -->
        </div><!-- /.section-content -->
      </div><!-- /.lt-section -->
    </div><!-- /.lt-page-content -->
  </div>
  <Footer/>
</template>

<style scoped lang="scss">

</style>