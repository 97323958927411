import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Products from "@/views/Products.vue";
import ProductDetail from "@/views/ProductDetail.vue";
import Blogs from "@/views/Blogs.vue";
import Contact from "@/views/Contact.vue";
import BlogsDetail from "@/views/BlogsDetail.vue";
import About from "@/views/About.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/products',
        name: 'Products',
        component: Products
    },
    {
        path: '/products/:str',
        name: 'ProductDetail',
        component: ProductDetail
    },
    {
        path: '/blogs',
        name: 'Blogs',
        component: Blogs
    },
    {
        path: '/blogs/:str',
        name: 'BlogsDetail',
        component: BlogsDetail
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
