<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {onMounted} from "vue";

onMounted(() => {
  const scripts = [
    '/static/js/jquery-3.6.0.min.js',
    '/static/js/bootstrap.min.js',
    '/static/js/popper.min.js',
    '/static/js/magnific-popup.min.js',
    '/static/js/slick.min.js',
    '/static/js/counterup.min.js',
    '/static/js/waypoints.min.js',
    '/static/js/jquery-ui-min.js',
    '/static/js/jquery.nice-select.min.js',
    '/static/js/theia-sticky-sidebar.min.js',
    '/static/js/main1.js'
  ];

  function loadScriptsSequentially(scripts, index = 0) {
    if (index < scripts.length) {
      const script = document.createElement('script');
      script.src = scripts[index];
      script.onload = () => loadScriptsSequentially(scripts, index + 1);
      document.body.appendChild(script);
    }
  }

  loadScriptsSequentially(scripts);
})
</script>

<template>
  <Header/>

  <Footer/>
</template>

<style scoped lang="scss">

</style>