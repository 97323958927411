<script setup>

</script>

<template>
  <header class="tl-header header-style-1">
    <div class="tl-topbar">
      <div class="container">
        <div class="d-flex justify-content-between">
          <div class="left-content">
            <ul class="global-list">
              <li><span class="icon"><img src="/static/picture/icon1.png" alt="Image" class="img-fluid"></span><a
                  href="tel:+8613802623082">+86 13802623082</a></li>
              <li><span class="icon"><img src="/static/picture/icon2.png" alt="Image" class="img-fluid"></span><a
                  href="mailto:dongxia52587@gmail.com">dongxia52587@gmail.com</a></li>
            </ul>
          </div><!-- /.left-content -->
          <div class="right-content align-self-center">
            <div class="topbar-user">
              <ul class="global-list">
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                <li><a href="#"><i class="fa fa-skype"></i></a></li>
              </ul>
            </div><!-- /.topbar-user -->
          </div><!-- /.right-content -->
        </div>
      </div><!-- /.container -->
    </div><!-- /.tl-topbar -->

    <div class="tl-menu menu-absolute menu-sticky">
      <nav class="navbar navbar-expand-lg p-0">
        <div class="container">
          <a class="navbar-brand" href="/">
            <img src="/static/picture/logo1.png" alt="Logo" class="img-fluid" style="width: 20%; height: 20%">
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"><i class="fa fa-align-justify"></i></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="navbar-nav">
              <li><a href="/">Home</a>
              </li>
              <li><a href="/about">About</a></li>
              <li class="tl-dropdown">
                <a href="/products">Products</a>
                <ul class="tl-dropdown-menu">
                  <li><a href="/products/?category_id_category=Aluminum Window">Aluminum Window</a></li>
                  <li><a href="/products/?category_id_category=Aluminum Door">Aluminum Door</a></li>
                  <li><a href="/products/?category_id_category=Sunroom">Sunroom</a></li>
                </ul>
              </li>
              <li><a href="/blogs">Blog</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
          <div class="right-content">
            <div class="lt-button">
              <a href="/contact" class="btn btn-transparent">Get Started</a>
            </div>
          </div>
        </div><!-- /.container -->
      </nav><!-- /.navbar -->
    </div><!-- /.tl-menu -->
  </header><!-- /.tl-header -->
</template>

<style scoped lang="scss">

</style>