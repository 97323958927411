<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {onMounted} from "vue";

onMounted(() => {
  const scripts = [
    '/static/js/jquery-3.6.0.min.js',
    '/static/js/bootstrap.min.js',
    '/static/js/popper.min.js',
    '/static/js/magnific-popup.min.js',
    '/static/js/slick.min.js',
    '/static/js/counterup.min.js',
    '/static/js/waypoints.min.js',
    '/static/js/jquery-ui-min.js',
    '/static/js/jquery.nice-select.min.js',
    '/static/js/theia-sticky-sidebar.min.js',
    '/static/js/main1.js'
  ];

  function loadScriptsSequentially(scripts, index = 0) {
    if (index < scripts.length) {
      const script = document.createElement('script');
      script.src = scripts[index];
      script.onload = () => loadScriptsSequentially(scripts, index + 1);
      document.body.appendChild(script);
    }
  }

  loadScriptsSequentially(scripts);
})
</script>

<template>
  <Header/>
  <div class="lt-breadcrumb">
    <div class="breadcrumb-content">
      <div class="container">
        <div class="d-flex justify-content-between">
          <div class="title">
            <h1>Blog Grid With Sidebar</h1>
          </div>
          <ol class="breadcrumb align-self-center">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Blog Grid</li>
          </ol>
        </div>
      </div><!-- /.container -->
    </div><!-- /.breadcrumb-content -->
  </div><!-- /.lt-breadcrumb -->

  <div class="lt-page-content bg-color">
    <div class="lt-section">
      <div class="section-content section-padding">
        <div class="container">
          <div class="lt-filter-form list-view-tab">
            <div class="lt-filter-form-content">
              <div class="lt-filter-form">
                <form action="#">
                  <div class="form-content">
                    <div class="search-content">
                      <input class="form-control" name="search" type="text" placeholder="Search here..">
                      <button type="submit"><i class="fa fa-search"></i></button>
                    </div>
                    <div class="form-group">
                      <select class="form-control">
                        <option>City</option>
                        <option>Washington</option>
                        <option>New Jersey</option>
                        <option>Los angeles</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select class="form-control">
                        <option>State</option>
                        <option>New York</option>
                        <option>California</option>
                        <option>Washington</option>
                      </select>
                    </div>
                    <div class="iocn align-self-center">
                      <a href="#"><i class="fa fa-exchange" aria-hidden="true"></i></a>
                    </div>
                    <div class="button">
                      <input type="submit" value="Search" class="btn btn-primary">
                    </div>
                  </div><!-- /.row -->
                </form>
              </div>

              <div class="lt-filter d-flex justify-content-between">
                <div class="left-content align-self-center">
                  <h3>Found 4 Agents</h3>
                </div><!-- /.left-content -->
                <div class="right-content d-flex">
                  <ul class="property-tabs global-list d-flex align-self-center">
                    <li class="grid-view-tab"><i class="fa fa-th-large" aria-hidden="true"></i></li>
                    <li class="list-view-tab active"><i class="fa fa-list" aria-hidden="true"></i></li>
                  </ul>
                  <div class="short-by">
                    <select class="form-control">
                      <option>Short by</option>
                      <option>For Sale</option>
                      <option>For Rent</option>
                    </select>
                  </div>
                </div>
              </div>
            </div><!-- /.lt-filter-content -->

            <div class="row">
              <div class="col-md-7 col-lg-8 lt-sticky">
                <div class="theiaStickySidebar">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/16.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Rent</a>
                            </div>
                            <div class="icons">
                              <ul class="global-list">
                                <li><a href="property-details.html"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="/static/picture/16.jpg" class="lt-popup"><i class="fa fa-search"
                                                                                         aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                            <div class="d-flex map-location justify-content-between">
                              <div class="left-content">
                                <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <span>8514 W. Pennington St</span>
                              </div>
                              <div class="heart-iocn">
                                <a href="#"><i class="fa fa-heart" aria-hidden="true"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="property-info">
                          <div class="info-top">
                            <h2><a href="property-details.html">Completely Mansion</a></h2>
                            <div class="meta">
                              <ul class="global-list">
                                <li><span><img src="/static/picture/icon7.png" alt="Image" class="img-fluid"></span>6
                                  Badroom
                                </li>
                                <li><span><img src="/static/picture/icon8.png" alt="Image" class="img-fluid"></span>6
                                  Bathroom
                                </li>
                                <li><span><img src="/static/picture/icon9.png" alt="Image" class="img-fluid"></span>6000
                                  Sf.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="property-footer d-flex justify-content-between">
                            <div class="author d-flex">
                              <span class="author-thumb"><img src="/static/picture/author1.png" alt="Image"
                                                              class="img-fluid"></span>
                              <span class="align-self-center">Anricia Socibalia</span>
                            </div><!-- /.author -->
                            <div class="price align-self-center">
                              <h3>$4,500/m</h3>
                            </div>
                          </div><!-- /.property-footer -->
                        </div><!-- /.property-info -->
                      </div><!-- /.property -->
                    </div>
                    <div class="col-md-4">
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/21.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Rent</a>
                            </div>
                            <div class="icons">
                              <ul class="global-list">
                                <li><a href="property-details.html"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="/static/picture/21.jpg" class="lt-popup"><i class="fa fa-search"
                                                                                         aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                            <div class="d-flex map-location justify-content-between">
                              <div class="left-content">
                                <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <span>8205 Myers Dr</span>
                              </div>
                              <div class="heart-iocn">
                                <a href="#"><i class="fa fa-heart" aria-hidden="true"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="property-info">
                          <div class="info-top">
                            <h2><a href="property-details.html">Quickly Simplify Services</a></h2>
                            <div class="meta">
                              <ul class="global-list">
                                <li><span><img src="/static/picture/icon7.png" alt="Image" class="img-fluid"></span>6
                                  Badroom
                                </li>
                                <li><span><img src="/static/picture/icon8.png" alt="Image" class="img-fluid"></span>6
                                  Bathroom
                                </li>
                                <li><span><img src="/static/picture/icon9.png" alt="Image" class="img-fluid"></span>6000
                                  Sf.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="property-footer d-flex justify-content-between">
                            <div class="author d-flex">
                              <span class="author-thumb"><img src="/static/picture/author2.png" alt="Image"
                                                              class="img-fluid"></span>
                              <span class="align-self-center">Anricia Socibalia</span>
                            </div><!-- /.author -->
                            <div class="price align-self-center">
                              <h3>$4,500/m</h3>
                            </div>
                          </div><!-- /.property-footer -->
                        </div><!-- /.property-info -->
                      </div><!-- /.property -->
                    </div>
                    <div class="col-md-4">
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/31.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Rent</a>
                            </div>
                            <div class="icons">
                              <ul class="global-list">
                                <li><a href="property-details.html"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="/static/picture/31.jpg" class="lt-popup"><i class="fa fa-search"
                                                                                         aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                            <div class="d-flex map-location justify-content-between">
                              <div class="left-content">
                                <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <span>7070 strong Ave.  VA 22015</span>
                              </div>
                              <div class="heart-iocn">
                                <a href="#"><i class="fa fa-heart" aria-hidden="true"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="property-info">
                          <div class="info-top">
                            <h2><a href="property-details.html">Globally Services Whereas</a></h2>
                            <div class="meta">
                              <ul class="global-list">
                                <li><span><img src="/static/picture/icon7.png" alt="Image" class="img-fluid"></span>6
                                  Badroom
                                </li>
                                <li><span><img src="/static/picture/icon8.png" alt="Image" class="img-fluid"></span>6
                                  Bathroom
                                </li>
                                <li><span><img src="/static/picture/icon9.png" alt="Image" class="img-fluid"></span>6000
                                  Sf.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="property-footer d-flex justify-content-between">
                            <div class="author d-flex">
                              <span class="author-thumb"><img src="/static/picture/author3.png" alt="Image"
                                                              class="img-fluid"></span>
                              <span class="align-self-center">Anricia Socibalia</span>
                            </div><!-- /.author -->
                            <div class="price align-self-center">
                              <h3>$4,500/m</h3>
                            </div>
                          </div><!-- /.property-footer -->
                        </div><!-- /.property-info -->
                      </div><!-- /.property -->
                    </div>
                    <div class="col-md-4">
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/41.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Rent</a>
                            </div>
                            <div class="icons">
                              <ul class="global-list">
                                <li><a href="property-details.html"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="/static/picture/41.jpg" class="lt-popup"><i class="fa fa-search"
                                                                                         aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                            <div class="d-flex map-location justify-content-between">
                              <div class="left-content">
                                <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <span>7462 Meadow Street</span>
                              </div>
                              <div class="heart-iocn">
                                <a href="#"><i class="fa fa-heart" aria-hidden="true"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="property-info">
                          <div class="info-top">
                            <h2><a href="property-details.html">Uniquely Deploy Strategies</a></h2>
                            <div class="meta">
                              <ul class="global-list">
                                <li><span><img src="/static/picture/icon7.png" alt="Image" class="img-fluid"></span>6
                                  Badroom
                                </li>
                                <li><span><img src="/static/picture/icon8.png" alt="Image" class="img-fluid"></span>6
                                  Bathroom
                                </li>
                                <li><span><img src="/static/picture/icon9.png" alt="Image" class="img-fluid"></span>6000
                                  Sf.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="property-footer d-flex justify-content-between">
                            <div class="author d-flex">
                              <span class="author-thumb"><img src="/static/picture/author4.png" alt="Image"
                                                              class="img-fluid"></span>
                              <span class="align-self-center">Anricia Socibalia</span>
                            </div><!-- /.author -->
                            <div class="price align-self-center">
                              <h3>$4,500/m</h3>
                            </div>
                          </div><!-- /.property-footer -->
                        </div><!-- /.property-info -->
                      </div><!-- /.property -->
                    </div>
                    <div class="col-md-4">
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/51.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Rent</a>
                            </div>
                            <div class="icons">
                              <ul class="global-list">
                                <li><a href="property-details.html"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="/static/picture/51.jpg" class="lt-popup"><i class="fa fa-search"
                                                                                         aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                            <div class="d-flex map-location justify-content-between">
                              <div class="left-content">
                                <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <span>764 West Wall Avenue</span>
                              </div>
                              <div class="heart-iocn">
                                <a href="#"><i class="fa fa-heart" aria-hidden="true"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="property-info">
                          <div class="info-top">
                            <h2><a href="property-details.html">Appropriately Competitive</a></h2>
                            <div class="meta">
                              <ul class="global-list">
                                <li><span><img src="/static/picture/icon7.png" alt="Image" class="img-fluid"></span>6
                                  Badroom
                                </li>
                                <li><span><img src="/static/picture/icon8.png" alt="Image" class="img-fluid"></span>6
                                  Bathroom
                                </li>
                                <li><span><img src="/static/picture/icon9.png" alt="Image" class="img-fluid"></span>6000
                                  Sf.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="property-footer d-flex justify-content-between">
                            <div class="author d-flex">
                              <span class="author-thumb"><img src="/static/picture/author5.png" alt="Image"
                                                              class="img-fluid"></span>
                              <span class="align-self-center">Anricia Socibalia</span>
                            </div><!-- /.author -->
                            <div class="price align-self-center">
                              <h3>$4,500/m</h3>
                            </div>
                          </div><!-- /.property-footer -->
                        </div><!-- /.property-info -->
                      </div><!-- /.property -->
                    </div>
                    <div class="col-md-4">
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/61.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Rent</a>
                            </div>
                            <div class="icons">
                              <ul class="global-list">
                                <li><a href="property-details.html"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="/static/picture/61.jpg" class="lt-popup"><i class="fa fa-search"
                                                                                         aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                            <div class="d-flex map-location justify-content-between">
                              <div class="left-content">
                                <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <span>808 Ryan Rd. GA 30240</span>
                              </div>
                              <div class="heart-iocn">
                                <a href="#"><i class="fa fa-heart" aria-hidden="true"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="property-info">
                          <div class="info-top">
                            <h2><a href="property-details.html">Proactively communicate.</a></h2>
                            <div class="meta">
                              <ul class="global-list">
                                <li><span><img src="/static/picture/icon7.png" alt="Image" class="img-fluid"></span>6
                                  Badroom
                                </li>
                                <li><span><img src="/static/picture/icon8.png" alt="Image" class="img-fluid"></span>6
                                  Bathroom
                                </li>
                                <li><span><img src="/static/picture/icon9.png" alt="Image" class="img-fluid"></span>6000
                                  Sf.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="property-footer d-flex justify-content-between">
                            <div class="author d-flex">
                              <span class="author-thumb"><img src="/static/picture/author7.png" alt="Image"
                                                              class="img-fluid"></span>
                              <span class="align-self-center">Anricia Socibalia</span>
                            </div><!-- /.author -->
                            <div class="price align-self-center">
                              <h3>$4,500/m</h3>
                            </div>
                          </div><!-- /.property-footer -->
                        </div><!-- /.property-info -->
                      </div><!-- /.property -->
                    </div>
                    <div class="col-md-4">
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/71.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Rent</a>
                            </div>
                            <div class="icons">
                              <ul class="global-list">
                                <li><a href="property-details.html"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="/static/picture/71.jpg" class="lt-popup"><i class="fa fa-search"
                                                                                         aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                            <div class="d-flex map-location justify-content-between">
                              <div class="left-content">
                                <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <span>808 Ryan Rd. GA 30240</span>
                              </div>
                              <div class="heart-iocn">
                                <a href="#"><i class="fa fa-heart" aria-hidden="true"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="property-info">
                          <div class="info-top">
                            <h2><a href="property-details.html">Proactively communicate.</a></h2>
                            <div class="meta">
                              <ul class="global-list">
                                <li><span><img src="/static/picture/icon7.png" alt="Image" class="img-fluid"></span>6
                                  Badroom
                                </li>
                                <li><span><img src="/static/picture/icon8.png" alt="Image" class="img-fluid"></span>6
                                  Bathroom
                                </li>
                                <li><span><img src="/static/picture/icon9.png" alt="Image" class="img-fluid"></span>6000
                                  Sf.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="property-footer d-flex justify-content-between">
                            <div class="author d-flex">
                              <span class="author-thumb"><img src="/static/picture/author8.png" alt="Image"
                                                              class="img-fluid"></span>
                              <span class="align-self-center">Anricia Socibalia</span>
                            </div><!-- /.author -->
                            <div class="price align-self-center">
                              <h3>$4,500/m</h3>
                            </div>
                          </div><!-- /.property-footer -->
                        </div><!-- /.property-info -->
                      </div><!-- /.property -->
                    </div>
                    <div class="col-md-4">
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/81.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Rent</a>
                            </div>
                            <div class="icons">
                              <ul class="global-list">
                                <li><a href="property-details.html"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="/static/picture/81.jpg" class="lt-popup"><i class="fa fa-search"
                                                                                         aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                            <div class="d-flex map-location justify-content-between">
                              <div class="left-content">
                                <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <span>808 Ryan Rd. GA 30240</span>
                              </div>
                              <div class="heart-iocn">
                                <a href="#"><i class="fa fa-heart" aria-hidden="true"></i></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="property-info">
                          <div class="info-top">
                            <h2><a href="property-details.html">Architects modern house</a></h2>
                            <div class="meta">
                              <ul class="global-list">
                                <li><span><img src="/static/picture/icon7.png" alt="Image" class="img-fluid"></span>6
                                  Badroom
                                </li>
                                <li><span><img src="/static/picture/icon8.png" alt="Image" class="img-fluid"></span>6
                                  Bathroom
                                </li>
                                <li><span><img src="/static/picture/icon9.png" alt="Image" class="img-fluid"></span>6000
                                  Sf.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="property-footer d-flex justify-content-between">
                            <div class="author d-flex">
                              <span class="author-thumb"><img src="/static/picture/author6.png" alt="Image"
                                                              class="img-fluid"></span>
                              <span class="align-self-center">Anricia Socibalia</span>
                            </div><!-- /.author -->
                            <div class="price align-self-center">
                              <h3>$4,500/m</h3>
                            </div>
                          </div><!-- /.property-footer -->
                        </div><!-- /.property-info -->
                      </div><!-- /.property -->
                    </div>
                  </div><!-- /.row -->
                  <div class="lt-pagination">
                    <ul class="pagination ">
                      <li><a class="page-numbers" href="#"><i class="fa fa-chevron-left"></i></a></li>
                      <li><span class="page-numbers current">1</span></li>
                      <li><a class="page-numbers" href="#">2</a></li>
                      <li><a class="page-numbers" href="#">3</a></li>
                      <li><a class="page-numbers" href="#"><i class="fa fa-chevron-right"></i></a></li>
                    </ul>
                  </div><!-- /.lt-pagination -->
                </div>
              </div>
              <div class="col-md-5 col-lg-4 lt-sidebar lt-sticky">
                <div class="widget-area theiaStickySidebar">
                  <div class="widget">
                    <h3 class="widget_title">Featured Properties</h3>
                    <div class="featured-slider">
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/sm-slider-2.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Hot</a>
                              <a href="#">Rent</a>
                            </div>
                            <div class="lt-price">
                              <span>$570,600</span>
                            </div>
                          </div>
                        </div>
                      </div><!-- /.property -->
                      <div class="property">
                        <div class="thumb">
                          <img src="/static/picture/sm-slider-2.jpg" alt="Image" class="img-fluid">
                          <div class="overlay">
                            <div class="property-status">
                              <a href="#">Hot</a>
                              <a href="#">Rent</a>
                            </div>
                            <div class="lt-price">
                              <span>$570,600</span>
                            </div>
                          </div>
                        </div>
                      </div><!-- /.property -->
                    </div><!-- /.featured-slider -->
                  </div><!-- /.widget -->

                  <div class="widget widget_property">
                    <h3 class="widget_title">Recently Viewed</h3>
                    <ul>
                      <li class="media">
                        <div class="entry-thumbnail">
                          <a href="property-details.html"><img src="/static/picture/1.png" alt="Image"
                                                               class="img-fluid"></a>
                        </div>
                        <div class="media-body">
                          <a href="property-details.html">Authoritatively harness</a>
                          <div class="map-location">
                            <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                            <span>780 Lynnway, Lynn MA 1905</span>
                          </div>
                          <span class="price">$1,000,000</span>
                        </div>
                      </li>
                      <li class="media">
                        <div class="entry-thumbnail">
                          <a href="property-details.html"><img src="/static/picture/2.png" alt="Image"
                                                               class="img-fluid"></a>
                        </div>
                        <div class="media-body">
                          <a href="property-details.html">Revolutionary markets</a>
                          <div class="map-location">
                            <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                            <span>780 Lynnway, Lynn MA 1905</span>
                          </div>
                          <span class="price">$1,000,000</span>
                        </div>
                      </li>
                      <li class="media">
                        <div class="entry-thumbnail">
                          <a href="property-details.html"><img src="/static/picture/3.png" alt="Image"
                                                               class="img-fluid"></a>
                        </div>
                        <div class="media-body">
                          <a href="property-details.html">Rapidiously coordinate</a>
                          <div class="map-location">
                            <span class="map-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                            <span>780 Lynnway, Lynn MA 1905</span>
                          </div>
                          <span class="price">$1,000,000</span>
                        </div>
                      </li>
                    </ul>
                  </div><!-- /.widget -->
                </div><!-- /.widget-area -->
              </div>
            </div><!-- /.row -->
          </div><!-- /.lt-filter-form -->
        </div><!-- /.container -->
      </div><!-- /.section-content -->
    </div><!-- /.lt-section -->
  </div><!-- /.lt-page-content -->

  <div class="lt-section">
    <div class="section-content section-padding text-center">
      <div class="container">
        <div class="tl-brand">
          <div class="brand-slider">
            <div class="brand">
              <img src="/static/picture/13.png" alt="Brand Image" class="img-fluid">
            </div>
            <div class="brand">
              <img src="/static/picture/22.png" alt="Brand Image" class="img-fluid">
            </div>
            <div class="brand">
              <img src="/static/picture/31.png" alt="Brand Image" class="img-fluid">
            </div>
            <div class="brand">
              <img src="/static/picture/4.png" alt="Brand Image" class="img-fluid">
            </div>
            <div class="brand">
              <img src="/static/picture/13.png" alt="Brand Image" class="img-fluid">
            </div>
            <div class="brand">
              <img src="/static/picture/22.png" alt="Brand Image" class="img-fluid">
            </div>
            <div class="brand">
              <img src="/static/picture/31.png" alt="Brand Image" class="img-fluid">
            </div>
            <div class="brand">
              <img src="/static/picture/4.png" alt="Brand Image" class="img-fluid">
            </div>
          </div><!-- /.brand-slider -->
        </div><!-- /.tl-brand -->
      </div><!-- /.container -->
    </div><!-- /.section-content -->
  </div><!-- /.lt-section -->

  <Footer/>
</template>

<style scoped lang="scss">

</style>