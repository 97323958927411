<script setup>

</script>

<template>
  <div class="tl-footer">
    <div class="footer-content bg-image" style="background-image: url(/static/image/footer.jpg);">
      <div class="container">
        <div class="footer-top">
          <div class="newsletter justify-content-between">
            <h3>Subscribe Our Newsletter</h3>
            <form action="#">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Enter Your E-mail">
                <input type="submit" class="btn btn-primary" value="Subscribe Now">
              </div>
            </form>
          </div>
        </div><!-- /.footer-top -->

        <div class="footer-middle">
          <div class="row">
            <div class="col-md-8 col-lg-4">
              <div class="footer-widget">
                <div class="footer-logo" style="display: flex; justify-content: center">
                  <img src="/static/picture/logo1.png" alt="Logo" class="img-fluid" style="width: 30%; height: 30%;">
                </div>
                <p>Holisticly aggregate compelling ROI before out-of-the-box benefits. Efficiently create cross
                  functional paradigms without an expanded array of testing procedures.</p>
                <span class="phone-number"><a href="tel:+8613802623082"> <span class="mr-2 fa fa-phone"></span>+86 13802623082</a></span>
              </div><!-- /.footer-widget -->
            </div>
            <div class="col-md-4 col-lg-2">
              <div class="footer-widget">

              </div><!-- /.footer-widget -->
            </div>
            <div class="col-md-8 col-lg-3">
              <div class="footer-widget">
                <h3>Navigation</h3>
                <ul class="global-list">
                  <li><a href="/about">About Us</a></li>
                  <li><a href="/products">Products</a></li>
                  <li><a href="/blogs">Blogs</a></li>
                  <li><a href="/contact">Contact</a></li>
                </ul>
              </div><!-- /.footer-widget -->
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="footer-widget">
                <h3>Products category</h3>
                <ul class="global-list">
                  <li><a href="/products/?category_id_category=Aluminum Window">Aluminum Window</a></li>
                  <li><a href="/products/?category_id_category=Aluminum Door">Aluminum Door</a></li>
                  <li><a href="/products/?category_id_category=Sunroom">Sunromm</a></li>
                </ul>
              </div><!-- /.footer-widget -->
            </div>
          </div><!-- /.row -->
        </div><!-- /.footer-middle -->

        <div class="footer-bottom clearfix">
          <div class="d-flex justify-content-between">
            <div class="left-content">
              <span>Copyright &copy; 2024.Company name All rights reserved.</span>
            </div>
            <div class="right-content">
              <ul class="global-list d-flex">
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>
          </div>
        </div><!-- /.footer-bottom -->
      </div><!-- /.container -->
    </div><!-- /.footer-content -->
  </div><!-- /.tl-footer -->
</template>

<style scoped lang="scss">

</style>