<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {onMounted} from "vue";

onMounted(() => {
  const scripts = [
    '/static/js/jquery-3.6.0.min.js',
    '/static/js/bootstrap.min.js',
    '/static/js/popper.min.js',
    '/static/js/magnific-popup.min.js',
    '/static/js/slick.min.js',
    '/static/js/counterup.min.js',
    '/static/js/waypoints.min.js',
    '/static/js/jquery-ui-min.js',
    '/static/js/jquery.nice-select.min.js',
    '/static/js/theia-sticky-sidebar.min.js',
    '/static/js/main1.js'
  ];

  function loadScriptsSequentially(scripts, index = 0) {
    if (index < scripts.length) {
      const script = document.createElement('script');
      script.src = scripts[index];
      script.onload = () => loadScriptsSequentially(scripts, index + 1);
      document.body.appendChild(script);
    }
  }

  loadScriptsSequentially(scripts);
})
</script>

<template>
  <Header/>
  <div class="lt-breadcrumb">
    <div class="breadcrumb-content">
      <div class="container">
        <div class="d-flex justify-content-between">
          <div class="title">
            <h1>Contact Us</h1>
          </div>
          <ol class="breadcrumb align-self-center">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
          </ol>
        </div>
      </div><!-- /.container -->
    </div><!-- /.breadcrumb-content -->
  </div><!-- /.lt-breadcrumb -->

  <div class="lt-page-content bg-color">
    <div class="lt-section">
      <div class="section-content lt-contact section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="contact-info bg-white">
                <div class="icon">
                  <i class="fa fa-volume-control-phone" aria-hidden="true"></i>
                </div>
                <span>+008 - 5069 - 9600</span>
                <span>+009 - 5069 - 5801</span>
              </div>
            </div>

            <div class="col-md-4">
              <div class="contact-info bg-white">
                <div class="icon">
                  <i class="fa fa-envelope-open-o" aria-hidden="true"></i>
                </div>
                <span><a href="mailto:example@mail.com">example@mail.com</a></span>
                <span><a href="mailto:example@mail.com">example@mail.com</a></span>
              </div>
            </div>

            <div class="col-md-4">
              <div class="contact-info bg-white">
                <div class="icon">
                  <i class="fa fa-map-o" aria-hidden="true"></i>
                </div>
                <span>City Street Suice 2/A Sydney, Australia 58000</span>
              </div>
            </div>

            <div class="col-md-7 col-lg-8">
              <div class="get-in-touch">
                <h3>Get In Touch</h3>
                <form  method="post" class="lt-form row">
                  <div class="form-group col-lg-6">
                    <input type="text" class="form-control" name="name" placeholder="Your Name">
                    <div class="input-addon">
                      <i class="fa fa-user-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <input type="email" class="form-control" name="email" placeholder="Your Email">
                    <div class="input-addon">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <select class="form-control" name="subject">
                      <option value="Subject">Subject</option>
                      <option value="rent-flat">Rent Flat</option>
                      <option value="sale-flat">Sale Flat</option>
                    </select>
                  </div>
                  <div class="form-group col-lg-6">
                    <input type="text" class="form-control" name="phone" placeholder="Phone Number">
                    <div class="input-addon">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="form-group col-lg-12">
                    <textarea cols="30" rows="10" class="form-control" placeholder="Your Message"
                              name="message"></textarea>
                    <div class="input-addon">
                      <i class="fa fa-comment-o" aria-hidden="true"></i>
                    </div>
                    <input type="submit" value="Send Now" name="send" class="btn btn-primary">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div><!-- container -->
      </div><!-- lt-agent-details -->
    </div><!-- lt-section -->


  </div><!-- /.lt-page-content -->


  <Footer/>
</template>

<style scoped lang="scss">

</style>